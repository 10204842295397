<template>
  <v-card flat tile >
    <v-card-title class="secondary">
      <span>Meters</span>
      <v-spacer></v-spacer>
      <v-text-field
        flat
        label="Search"
        prepend-inner-icon="search"
        hide-details
        solo
        v-model="searchMeter"
        clearable
        @click:clear="clearSearch"
      ></v-text-field>
    </v-card-title>
    <v-card-text style="overflow:auto;height:850px">
      <v-list dense>
        <v-list-item-group v-model="selectedMeter" mandatory color="indigo">
          <template v-for="(meter, index) in filteredmeters">
            <v-list-item
              two-line
              :key="meter.serial_number"
              @click="onMeterClick(meter)"
              :value="meter.serial_number"
            >
              <!-- <v-list-item-avatar>
              <v-icon>developer_board</v-icon>
              </v-list-item-avatar>-->
              <v-list-item-content>
                <v-list-item-title>{{meter.name}}</v-list-item-title>
                <v-list-item-subtitle>{{meter.serial_number}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="index + 1 < filteredmeters.length" :key="index"></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { EventBus } from "@/lib/EventBus";
export default {
  name: "MetersWidget",
  components: {},
  data() {
    return {
      selectedMeter: null,
      searchMeter: ""
    };
  },
  mounted() {
     EventBus.$on("select-analytics-meter", (meter) => {
       this.selectedMeter  = meter;
     });
  },
  computed: {
    meters() {
      var self = this;
      if (
        self.$store.getters["MDMStore/getterMeters"].length != 0 &&
        self.selectedMeter == null
      ) {
        var selectedMeterDetails =
          self.$store.getters["MDMStore/getterMeters"][0];
        self.selectedMeter = selectedMeterDetails.serial_number;
        self.$store.dispatch({
          type: "MDMStore/getDataSources",
          deviceInfo: {
            gatewayId: selectedMeterDetails.gateway.serial_number,
            meterId: selectedMeterDetails.serial_number
          }
        });
        if (
          this.$store.getters["MDMStore/getterSelectedMeter"] == "" ||
          this.$store.getters["MDMStore/getterSelectedMeter"] == null
        ) {
          self.$store.commit(
            "MDMStore/SET_SELECTED_METER",
            selectedMeterDetails.serial_number
          );
        }
      }
      return self.$store.getters["MDMStore/getterMeters"];
    },
    filteredmeters() {
      if (!this.searchMeter) return this.meters;
      else {
        return this.meters.filter(
          item =>
            item.serial_number
              .toLowerCase()
              .includes(this.searchMeter.toLowerCase()) ||
            item.name.toLowerCase().includes(this.searchMeter.toLowerCase())
        );
      }
    }
  },
  methods: {
    onMeterClick(meter) {
      this.$store.dispatch({
        type: "MDMStore/getDataSources",
        deviceInfo: {
          gatewayId: meter.gateway.serial_number,
          meterId: meter.serial_number
        }
      });
      this.$store.commit("MDMStore/SET_SELECTED_METER", meter.serial_number);
    },
    clearSearch() {
      this.searchMeter = "";
    }
  }
};
</script>
<style scoped>
</style>

  