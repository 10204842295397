<template>
  <v-container fluid pa-2>
    <v-row no-gutters>
      <v-col cols="3" md="3" sm="3" lg="3">
        <v-card>
          <meters-widget></meters-widget>
        </v-card>
      </v-col>
      <v-col cols="12" md="9" sm="9" lg="9" class="px-2">
        <v-card class="px-2 py-0">
          <v-row no-gutters>
            <v-col cols="12" md="4" sm="4" lg="4" class="pr-2 align-self-center">
              <v-menu
                v-model="startDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    hide-details
                    v-model="startDateValue"
                    label="Start Date"
                    append-icon="event"
                    readonly
                    solo
                    flat
                    outlined
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :show-current="true"
                  v-model="startDateValue"
                  @input="onDateChange"
                  :max="new Date().toISOString().substr(0, 10)"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col md="4" sm="4" lg="4" class="pr-2 align-self-center">
              <v-menu
                v-model="endDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    hide-details
                    v-model="endDateValue"
                    label="End Date"
                    append-icon="event"
                    readonly
                    solo
                    flat
                    outlined
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDateValue"
                  @input="onDateChange"
                  :max="new Date().toISOString().substr(0, 10)"
                  :min="startDateValue"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn-toggle
                v-model="timeRangeSelected"
                v-on:change="onTimeRangeChange"
                color="primary darken-1"
                tile
                group
              >
                <v-btn fab value="1d">DAY</v-btn>
                <v-btn value="1w">WEEK</v-btn>
                <v-btn value="4w">MONTH</v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col class="align-self-center">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    text
                    icon
                    dark
                    medium
                    color="primary darken-1"
                    @click="exportAllParamData()"
                  >
                    <v-icon dark>vertical_align_bottom</v-icon>
                  </v-btn>
                </template>
                <span>Export all params data to CSV</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
        <v-row no-gutters class="mt-1">
          <v-col>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header expand-icon="mdi-menu-down" class="blue--text">Data Params</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-chip-group multiple column active-class="primary--text">
                    <v-chip
                      color="secondary"
                      v-for="(p) in dataParams"
                      :key="p"
                      @click="loadChartDialog(p)"
                    >{{p}}</v-chip>
                  </v-chip-group>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-1">
          <v-col
            v-for="(param, key, index) in Object.keys(paramsWithData)"
            :key="key + '' + index"
            cols="12"
            sm="12"
            md="12"
            lg="12"
            class="mt-1"
          >
            <v-card>
              <v-card-title class="secondary pa-0">
                <v-row no-gutters class="align-center">
                  <v-col cols="12" sm="8" lg="8" md="8" class="align-self-center pl-2">
                    <span class="pa-0">{{getMeterName(meterSelected)}} - {{param}}</span>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col class="text-right">
                    <v-btn-toggle
                      tile
                      group
                      color="primary darken-1"
                      v-model="paramsWithData[param].chartOrTableFlag"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" value="table">
                            <v-icon>table_chart</v-icon>
                          </v-btn>
                        </template>
                        <span>Table view</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" value="chart">
                            <v-icon>insert_chart</v-icon>
                          </v-btn>
                        </template>
                        <span>Chart view</span>
                      </v-tooltip>
                    </v-btn-toggle>
                  </v-col>
                  <v-col cols="auto" class="text-right mx-3">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          text
                          icon
                          dark
                          medium
                          color="primary darken-1"
                          @click="exportSingleParamData(param)"
                        >
                          <v-icon dark>vertical_align_bottom</v-icon>
                        </v-btn>
                      </template>
                      <span>Export to CSV</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-title>

              <v-divider></v-divider>
              <v-card-text
                v-if="paramsWithData.hasOwnProperty(param) && paramsWithData[param].chartOrTableFlag == 'chart'"
              >
                <bar-chart :chart-data="paramsWithData[param]" :options="options"></bar-chart>
              </v-card-text>

              <v-card-text
                v-if="paramsWithData.hasOwnProperty(param) && paramsWithData[param].chartOrTableFlag == 'table'"
              >
                <v-simple-table height="300px" fixed-header>
                  <thead>
                    <tr>
                      <th class="text-center">Time stamp</th>
                      <th class="text-center">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in paramsWithData[param].chartData" :key="item.time">
                      <td class="text-center">{{ (item.time).split("T")[0] }}</td>
                      <td class="text-center align-center">{{ item.mean }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-2" v-if="Object.keys(paramsWithData).length == 0">
          <v-col>
            <v-card class="text-center"> No data available</v-card>
          </v-col>
        </v-row>
        <!-- <v-row no-gutters class="pt-2">
          <v-col
            v-for="(param, key, index) in Object.keys(paramsWithNoData)"
            :key="key + '' + index"
            cols="12"
            sm="12"
            md="12"
            lg="12"
            class="mt-1"
          >
            <v-card>
              <v-card-title class="secondary pa-0">
                <v-row no-gutters class="align-center">
                  <v-col cols="12" sm="8" lg="8" md="8" class="align-self-center pl-2">
                    <span class="pa-0">{{getMeterName(meterSelected)}} - {{param}}</span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <span>No data available</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row> -->
      </v-col>
    </v-row>
    <v-dialog v-model="showChartDialog" max-width="1200">
      <v-card class="blue-grey lighten-5">
        <v-card-title>
          <span class="pa-0">{{getMeterName(meterSelected)}} - {{dialogParam}}</span>
          <v-spacer></v-spacer>
          <v-btn icon color="purple" @click="closeChartDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4" v-if="chartDialogData != null">
          <bar-chart
            v-if="chartDialogData.labels.length != 0"
            :chart-data="chartDialogData"
            :options="options"
          ></bar-chart>
        </v-card-text>
        <v-card-text v-else>
            <span>No data available</span>
        </v-card-text>
        <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="showMetersDialog = false">Close</v-btn>
        </v-card-actions>-->
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import MetersWidget from "../components/analytics/metersWidget";
import MeterDataSources from "../components/analytics/meterDataSources";
import BarChart from "@/lib/BarChart.js";
import apiHelper from "../apiHelper";
import json2csv from "json2csv";

export default {
  components: {
    MetersWidget,
    MeterDataSources,
    BarChart
  },
  data() {
    return {
      showChartDialog: false,
      startDateValue: null,
      dialogParam: "",
      startDate: false,
      endDateValue: null, //new Date().toLocaleDateString(),
      endDate: false,
      timeRangeSelected: "1d",
      paramsWithData: [],
      paramsWithNoData: [],
      dataParams: [],
      chartDialogData: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: true
              },
              ticks: {
                stepSize: 10,
                maxTicksLimit: 3
              }
            }
          ]
        },
        legend: {
          labels: {
            fontSize: 16,
            fontColor: "black",
            boxWidth: 20,
            fontFamily: "lato"
          }
        }
      }
    };
  },
  computed: {
    meterSelected() {
      return this.$store.getters["MDMStore/getterSelectedMeter"];
    }
  },
  watch: {
    meterSelected() {
      this.getChartsData();
    }
  },
  mounted() {
    this.setStartAndEndDates();
    this.$store.dispatch({
      type: "MDMStore/getOrgDetails"
    });
    this.$store.dispatch("MDMStore/getInfluxMeasurements");

    this.$store.dispatch({
      type: "MDMStore/getMeters",
      orgId: localStorage.getItem("orgId")
    });
    this.getChartsData();
  },
  created() {},
  methods: {
    onDateChange() {
      this.startDate = false;
      this.endDate = false;
      this.getChartsData();
    },
    async loadChartDialog(p) {
      this.dialogParam = p;
      this.showChartDialog = true;
      var postData = {
        meterId: this.$store.getters["MDMStore/getterSelectedMeter"],
        measurement: p,
        startTime: this.startDateValue,
        endTime: this.endDateValue,
        timeRange: this.timeRangeSelected
      };
      var dialogChartData = await apiHelper(
        "POST",
        "mdm/influx/measurementdata",
        postData
      );
      var labelArray = dialogChartData.data.map(point =>
        point["time"].substring(0, 10)
      );
      var dataArray = dialogChartData.data.map(point => point["mean"]);
      this.chartDialogData = {
        labels: labelArray,
        datasets: [
          {
            label: p,
            data: dataArray,
            backgroundColor: "#66BB6A"
          }
        ]
      };
    },
    closeChartDialog() {
      this.showChartDialog = false;
    },
    onTimeRangeChange(range) {
      this.timeRangeSelected = range;
      this.getChartsData();
    },
    formatDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    setStartAndEndDates() {
      this.endDateValue = this.formatDate(
        new Date().toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric"
        })
      );
      var startDateRange = new Date();
      startDateRange.setDate(startDateRange.getDate() - 30);
      this.startDateValue = this.formatDate(
        startDateRange.toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric"
        })
      );
    },

    async getChartsData() {
      var self = this;
      (this.paramsWithData = []),
        (this.paramsWithNoData = []),
        (this.dataParams = []);
      var postData = {
        meterId: self.$store.getters["MDMStore/getterSelectedMeter"],
        startTime: self.startDateValue,
        endTime: self.endDateValue,
        timeRange: self.timeRangeSelected
      };
      var chartData = await apiHelper(
        "POST",
        "mdm/influx/allmeasurementsdata",
        postData
      );

      chartData.data.map(p => {
        var labelArray = p.data.map(point => point["time"].substring(0, 10));
        var dataArray = p.data.map(point => point["mean"]);
        if (p.data.length != 0) {
          self.$set(this.paramsWithData, p.param, {
            chartOrTableFlag: "chart",
            chartData: p.data,
            labels: labelArray,
            datasets: [
              {
                label: p.param + "-" + p.unit,
                fill: false,
                backgroundColor: this.getRandomColor(),
                data: dataArray
              }
            ]
          });
        } else {
          self.$set(this.paramsWithNoData, p.param, {
            chartOrTableFlag: "chart",
            chartData: p.data,
            labels: labelArray,
            datasets: [
              {
                label: p.param,
                fill: false,
                backgroundColor: this.getRandomColor(),
                data: dataArray
              }
            ]
          });
        }
      });
      this.dataParams = [
        ...Object.keys(this.paramsWithData),
        ...Object.keys(this.paramsWithNoData)
      ];
    },
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    getParamName(param) {
      var dataSources = this.$store.getters[
        "MDMStore/getterSelectedMeterDataSources"
      ];
      var ds = dataSources.filter(ds => (ds.name || ds) == param);
      return ds[0];
    },
    getMeterName(meterId) {
      var meters = this.$store.getters["MDMStore/getterMeters"];
      var meterDetails = meters.filter(function(meter) {
        return meter.serial_number === meterId;
      });
      return meterDetails.length != 0 && meterDetails[0].hasOwnProperty("name")
        ? meterDetails[0].name
        : "";
    },
    exportAllParamData() {
      var self = this;
      var data = [];
      var dataParams = Object.keys(this.paramsWithData);
      dataParams.forEach(p => {
        data = [
          ...data,
          ...this.paramsWithData[p].chartData.map(point =>
            Object.assign({
              param: p,
              time: point.time,
              value: point.mean
            })
          )
        ];
      });
      this.CsvExport(data, this.meterSelected + "_all_params_data");
      // this,paramsWithData[dataParams[]]
      //  Object.keys(this.paramsWithData).forEach((p)=> this.paramsWithData[p].chartData.forEach((point,l) => this.data.push(
      //    {
      //      param:p,
      //     time:point[l].time,
      //     value:point[l].mean
      //    })));
      // this.CsvExport(data);
    },
    exportSingleParamData(param) {
      var data = this.paramsWithData[param].chartData.map((p, i) =>
        Object.assign(
          {
            time: p.time,
            value: p.mean
          },
          {}
        )
      );
      this.CsvExport(data, this.meterSelected + "_" + param);
      this.CsvExport(data);
    },
    CsvExport(data, fileName) {
      const fields = ["time", "value"];
      try {
        const parser = new json2csv.Parser();
        const opts = {
          fields
        };
        const csv = parser.parse(data, opts);
        var csvContent = "data:text/csvcharset=GBK,\uFEFF" + csv;
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${fileName}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>