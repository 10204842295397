<template>
  <v-card flat tile>
    <v-card-title class="secondary">Data Params</v-card-title>
    <v-progress-linear :active="loading" :indeterminate="loading" absolute color="primary darken-1"></v-progress-linear>
    <v-card-text>
      <v-list dense>
        <v-list-item-group v-model="selectedDataSources" multiple mandatory color="indigo">
          <template v-for="(dataSource, index) in getDataSources">
          <v-list-item
            :key="dataSource.name || dataSource"
            :value="dataSource.name || dataSource"
          >
            <!-- <v-list-item-icon>
              <v-icon>fiber_manual_record</v-icon>
            </v-list-item-icon> -->
            <v-list-item-content>
              <v-list-item-title>{{dataSource.alias || dataSource.name || dataSource}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider
            v-if="index + 1 < getDataSources.length"
            :key="index"
          ></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card-text>
    <!-- <v-card-text>
       <v-chip-group
        v-model="selectedDataSources"
        column
        multiple
      >
        <v-chip v-for="(dataSource,index) in getDataSources" :key="index" filter outlined>{{dataSource.alias || dataSource.name}}</v-chip>
      </v-chip-group> 
    </v-card-text>-->
  </v-card>
</template>

<script>
export default {
  name: "MeterDataSources",
  components: {},
  data() {
    return {
      selectedDataSources: []
    };
  },
  mounted() {},
  computed: {
    getDataSources() {
      return this.$store.getters["MDMStore/getterSelectedMeterDataSources"];
    },
    loading(){
      return this.$store.getters["MDMStore/getterloadingState"](
        "METER_DATA_PARAMS"
      );
    }
  },
  watch: {
    getDataSources(dataSources) {
      if (this.selectedDataSources.length == 0 && dataSources.length != 0) {
        // this.selectedDataSources  = dataSources;
       
        this.selectedDataSources = dataSources.map(ds => (ds.name || ds));
        // push(dataSources[0].name || dataSources[0]);
      }
    },
    selectedDataSources() {
      this.$store.commit(
        "MDMStore/SET_SELECTED_METER_DATA_SOURCES",
        this.selectedDataSources
      );
    }
  },
  methods: {}
};
</script>
<style scoped>
</style>

  